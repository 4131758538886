<template>
  <div
    v-if="forgeStatus === 'success'"
    class="d-flex flex-grow-1 flex-scroll-height"
  >
    <forge-viewer
      ref="forge-viewer"
      style="height: auto"
      :client="forgeData.CFFA_FORGE_CLIENT.records[0]"
      :models="forgeData.CFFA_FORGE_MODELS.records"
      :extensions="[
        'Autodesk.DocumentBrowser',
        'Autodesk.Geolocation',
        'Autodesk.VisualClusters',
      ]"
      :extension-options="[]"
      :custom-extensions="['RfisExtension']"
      class="flex-grow-1"
    />
    <ant-view-loading :loading="$wait.is(`tasks.get.*`)" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FORGE } from '@/modules/modules';
import AntViewLoading from '@/components/AntViewLoading';

export default {
  name: 'Forge',
  components: {
    AntViewLoading,
    ForgeViewer: () =>
      import(
        /* webpackChunkName: "forge" */ '../components/Modules/Forge/ForgeViewer'
      ),
  },
  data: () => {
    return {
      models: [],
    };
  },
  computed: {
    ...mapGetters(['project', 'forgeData', 'forgeStatus', 'selectedSbsObject']),
  },
  watch: {
    forgeStatus(value) {
      if (value === 'success') {
        if (this.$route.params.sessionId) {
          this.forgeData.CFFA_FORGE_MODELS.records.forEach((record) => {
            record.enabled = record.session === this.$route.params.sessionId;
            this.models.push(record);
          });
        }
      }
    },
  },
  beforeRouteUpdate(to) {
    if (to.params.modelId) {
      this.$store.commit('module_forge_enable_models', [to.params.modelId]);
    }
  },
  mounted() {
    this.$store
      .dispatch('loadForgeModuleData', {
        projectId: this.project.id,
        moduleId: this.project.modules.find((module) => module.route === FORGE)
          .id,
        sessionId: this.$route.params.sessionId ?? null,
      })
      .then(() => {
        if (this.$route.params.modelId) {
          this.$store.commit('module_forge_enable_models', [
            this.$route.params.modelId,
          ]);
        }
        this.$store.dispatch('fetchRfiTasks');
      });
  },
};
</script>

<style scoped lang="scss"></style>
